import { Entity } from '@decahedron/entity'

export class Shop extends Entity {
  id: string = null
  name: string = null
  position: number = null
  color: string = null

  get abbreviation (): string {
    return this.name ? this.name.match(/[A-Z]/g).join('') : ''
  }
}


import { Options, Vue } from 'vue-class-component'
import { MenuAlt3Icon } from '@heroicons/vue/outline'

@Options({
  components: {
    MenuAlt3Icon
  }
})
export default class App extends Vue {
  menuVisible = false

  showMenu () {
    this.menuVisible = true
  }
}

<template>
  <div id="header">
    <h1 id="title">Sholi</h1>
    <MenuAlt3Icon class="icon cursor-pointer" @click="showMenu" />
  </div>
  <div id="content">
    <router-view />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { MenuAlt3Icon } from '@heroicons/vue/outline'

@Options({
  components: {
    MenuAlt3Icon
  }
})
export default class App extends Vue {
  menuVisible = false

  showMenu () {
    this.menuVisible = true
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif:ital,wght@1,400;1,700&display=swap');

body {
  @apply m-0 p-0 h-screen;
}

#app {
  @apply text-lg subpixel-antialiased h-full flex flex-col justify-between items-center;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(4, 99, 245);

  #header {
    @apply flex justify-between w-full max-w-xl items-center;

    h1#title {
      @apply text-white p-8 m-0;
      font-family: 'Noto Serif', serif;
    }

    .icon {
      @apply mr-8 text-white w-7 h-7;
    }

    @screen md {
      h1#title, .icon {
        @apply px-0 mx-0;
      }
    }
  }

  div#content {
    @apply flex-grow w-full max-w-xl;
  }

  .icon {
    @apply w-5 h-5;
  }

  input[type=text] {
    @apply px-2 py-2 placeholder-gray-300 text-gray-600 relative bg-white rounded text-sm border-0 shadow outline-none w-full;

    &:focus {
      @apply outline-none ring;
    }
  }

  input[type=color] {
    @apply p-0 placeholder-gray-300 text-gray-600 relative h-8 border-0 shadow outline-none;
  }
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>

import { AbstractRepository } from '@/core/repositories/AbstractRepository'
import { CancelablePromise } from '@/core/repositories/CancelablePromise'
import { EntityBuilder } from '@decahedron/entity'
import { Product } from '@/packages/product/Product'
import { AxiosPromise } from 'axios'

export class ProductRepository extends AbstractRepository<Product> {
  protected baseUrl = process.env.VUE_APP_API_URL
  protected url = 'products'
  protected entity = Product

  list (): CancelablePromise<Product[]> {
    return this.get(this.url)
      .then(response => EntityBuilder.buildMany<Product>(Product, response.data.data))
  }

  save (product: Product): Promise<Product> {
    if (product.id) {
      return this.put(`${this.url}/${product.id}`, product)
    }

    return this.post(this.url, product)
  }

  delete (id: string): AxiosPromise<void> {
    return super.delete(id)
  }
}

import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Products from '../packages/product/views/Products.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Products',
    component: Products
  },
  {
    path: '/shops',
    name: 'Shops',
    component: () => import(/* webpackChunkName: "about" */ '../packages/shop/views/Shops.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

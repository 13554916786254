import { Entity, Type } from '@decahedron/entity'
import { Shop } from '@/packages/shop/Shop'

export class Product extends Entity {
  id: string = null
  name: string = null
  @Type(Shop) shop: Shop = null
  position: number = null
  color: string = null
}

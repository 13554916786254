
import { Options, Vue } from 'vue-class-component'
import { container } from 'tsyringe'
import { CheckCircleIcon, PlusCircleIcon, TrashIcon, XCircleIcon } from '@heroicons/vue/outline'
import Pusher from 'pusher-js'
import { Product } from '@/packages/product/Product'
import { ProductRepository } from '@/packages/product/ProductRepository'
import { EntityBuilder } from '@decahedron/entity'
import { pusher } from '@/core/Pusher'

@Options({
  components: {
    TrashIcon,
    CheckCircleIcon,
    XCircleIcon,
    PlusCircleIcon
  }
})
export default class Products extends Vue {
  products: Product[] = null
  productEditor = new Product()
  pusher: Pusher = null

  productRepository = container.resolve(ProductRepository)

  async mounted () {
    this.products = await this.productRepository.list()
    this.subscribe()
  }

  unmounted () {
    this.pusher.disconnect()
  }

  subscribe () {
    this.pusher = pusher('my-channel')
    this.pusher.bind('product-event', (data: any) => {
      const action = data.action
      const product = EntityBuilder.buildOne<Product>(Product, data.product)

      if (action === 'create') {
        this.push(product)
      } else if (action === 'update') {
        this.update(product)
      } else if (action === 'delete') {
        this.delete(product)
      }
    })
  }

  push (product: Product) {
    if (!this.products.find(existingProduct => existingProduct.id === product.id)) {
      this.products.push(product)
    }
  }

  update (product: Product) {
    this.products = this.products.map((existingProduct: Product) => {
      if (existingProduct.id === product.id) {
        return product
      }

      return existingProduct
    })
  }

  delete (product: Product) {
    this.products = this.products.filter(existingProduct => existingProduct.id !== product.id)
  }

  async create () {
    const savedProduct = await this.save(this.productEditor)
  }

  async save (product: Product) {
    const savedProduct = await this.productRepository.save(product)

    if (!product.id) {
      this.push(savedProduct)
    }

    this.reset()

    return savedProduct
  }

  reset () {
    this.productEditor = new Product()
  }

  edit (product: Product) {
    this.productEditor = product;

    (this.$refs.productName as HTMLElement).focus()
  }

  remove (product: Product) {
    this.productRepository.delete(product.id)
    this.products = this.products.filter((filterProduct: Product) => filterProduct.id !== product.id)
  }
}

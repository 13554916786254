// eslint-disable-next-line
// @ts-ignore
export interface ICancelablePromise<T> extends Promise<T> {
  cancel?: Function;

  then<TResult1 = T, TResult2 = never> (
    onFulfilled?: (value: T) => TResult1 | PromiseLike<TResult1> | undefined | null,
    onRejected?: (reason: any) => TResult2 | PromiseLike<TResult2> | undefined | null,
  ): Promise<TResult1 | TResult2> | CancelablePromise<TResult1 | TResult2> | ICancelablePromise<TResult1 | TResult2>;

  catch<TResult = never> (
    onrejected?: ((reason: any) => TResult | PromiseLike<TResult> | void | null | undefined),
  ): Promise<T | TResult> | CancelablePromise<T | TResult> | ICancelablePromise<T | TResult>;

  finally (
    onfinally?: (() => void) | undefined | null,
  ): Promise<T> | CancelablePromise<T> | ICancelablePromise<T>;
}

export class CancelablePromise<T> implements ICancelablePromise<T> {
  private localResolve: (value?: T | PromiseLike<T>) => void
  private localReject: (reason?: any) => void
  // eslint-disable-next-line no-use-before-define
  public promise: ICancelablePromise<T>
  public cancel: Function = (): any => null
  executor: (resolve: (value?: T | PromiseLike<T>) => void, reject: (reason?: any) => void) => void
  // Required to type extend Promise with the correct type. If removed the
  // Promise interface is incorrectly implemented and TS will throw an error
  readonly [Symbol.toStringTag]: 'Promise'

  constructor (executor: (resolve: (value?: T | PromiseLike<T>) => void, reject: (reason?: any) => void) => void) {
    const promise = new Promise<T>((resolve, reject) => {
      this.localResolve = resolve
      this.localReject = reject
      return executor(resolve, reject)
    }) as ICancelablePromise<T>
    promise.cancel = this.cancel

    this.promise = promise
  }

  then<TResult1 = T, TResult2 = never> (
    onFulfilled?: (value: T) => TResult1 | PromiseLike<TResult1> | undefined | null,
    onRejected?: (reason: any) => TResult2 | PromiseLike<TResult2> | undefined | null
  ): CancelablePromise<TResult1 | TResult2> {
    return this.cloneForUpChaining<TResult1 | TResult2>(this.promise.then(onFulfilled, onRejected) as ICancelablePromise<TResult1>)
  }

  catch (onRejected?: (reason: any) => PromiseLike<never> | void): Promise<T> | CancelablePromise<T> | ICancelablePromise<T> {
    return this.cloneForUpChaining(this.promise.catch(onRejected) as ICancelablePromise<T>) as ICancelablePromise<T>
  }

  finally (onFinally?: (() => void) | undefined | null): Promise<T> | CancelablePromise<T> | ICancelablePromise<T> {
    return this.cloneForUpChaining(this.promise.finally(onFinally) as ICancelablePromise<T>)
  }

  resolve (value?: T | PromiseLike<T>): void {
    return this.localResolve(value)
  }

  reject (reason?: any): void {
    return this.localReject(reason)
  }

  private cloneForUpChaining<TResult = T> (promise: ICancelablePromise<TResult>): CancelablePromise<TResult> {
    const returnPromise = new CancelablePromise<TResult>(() => null)
    returnPromise.cancel = this.cancel
    promise.cancel = this.cancel
    returnPromise.promise = promise
    return returnPromise
  }
}

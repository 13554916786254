export interface IParams {
  [key: string]: string | number;
}

export interface IQuery {
  [key: string]: any;
}

export default function buildPath (url: string, params?: IParams, query?: IQuery): string {
  if (query) {
    return `${url}?${new URLSearchParams(query).toString()}`
  }

  return url
}

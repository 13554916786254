<template>
  <div class="products" v-if="products">
    <div>
      <div class="product" v-for="product in products" :key="product.id" @click="edit(product)">
        <div class="name">
          <span>{{ product.name }}</span>
        </div>
        <CheckCircleIcon class="icon cursor-pointer" @click.stop="remove(product)" />
      </div>
    </div>
    <div>
      <div class="product">
        <div class="name">
          <input
            ref="productName"
            type="text"
            class="w-full m-0"
            v-model="productEditor.name"
            @keyup.enter="save(productEditor)"
            @keyup.esc="reset" />
        </div>
        <div class="actions">
          <XCircleIcon v-if="productEditor.name" class="icon cursor-pointer" @click="reset" />
          <PlusCircleIcon v-if="!productEditor.id" class="icon cursor-pointer" @click="save(productEditor)" />
          <CheckCircleIcon v-else class="icon cursor-pointer" @click="save(productEditor)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { container } from 'tsyringe'
import { CheckCircleIcon, PlusCircleIcon, TrashIcon, XCircleIcon } from '@heroicons/vue/outline'
import Pusher from 'pusher-js'
import { Product } from '@/packages/product/Product'
import { ProductRepository } from '@/packages/product/ProductRepository'
import { EntityBuilder } from '@decahedron/entity'
import { pusher } from '@/core/Pusher'

@Options({
  components: {
    TrashIcon,
    CheckCircleIcon,
    XCircleIcon,
    PlusCircleIcon
  }
})
export default class Products extends Vue {
  products: Product[] = null
  productEditor = new Product()
  pusher: Pusher = null

  productRepository = container.resolve(ProductRepository)

  async mounted () {
    this.products = await this.productRepository.list()
    this.subscribe()
  }

  unmounted () {
    this.pusher.disconnect()
  }

  subscribe () {
    this.pusher = pusher('my-channel')
    this.pusher.bind('product-event', (data: any) => {
      const action = data.action
      const product = EntityBuilder.buildOne<Product>(Product, data.product)

      if (action === 'create') {
        this.push(product)
      } else if (action === 'update') {
        this.update(product)
      } else if (action === 'delete') {
        this.delete(product)
      }
    })
  }

  push (product: Product) {
    if (!this.products.find(existingProduct => existingProduct.id === product.id)) {
      this.products.push(product)
    }
  }

  update (product: Product) {
    this.products = this.products.map((existingProduct: Product) => {
      if (existingProduct.id === product.id) {
        return product
      }

      return existingProduct
    })
  }

  delete (product: Product) {
    this.products = this.products.filter(existingProduct => existingProduct.id !== product.id)
  }

  async create () {
    const savedProduct = await this.save(this.productEditor)
  }

  async save (product: Product) {
    const savedProduct = await this.productRepository.save(product)

    if (!product.id) {
      this.push(savedProduct)
    }

    this.reset()

    return savedProduct
  }

  reset () {
    this.productEditor = new Product()
  }

  edit (product: Product) {
    this.productEditor = product;

    (this.$refs.productName as HTMLElement).focus()
  }

  remove (product: Product) {
    this.productRepository.delete(product.id)
    this.products = this.products.filter((filterProduct: Product) => filterProduct.id !== product.id)
  }
}
</script>

<style lang="scss">
.products {
  @apply bg-gray-200 rounded-t-xl py-1 h-full flex flex-col;

  .product {
    @apply m-2 px-3 py-3 flex justify-between items-center text-lg rounded-xl bg-white shadow;

    .name {
      @apply flex items-center;

      .abbreviation {
        @apply rounded-xl bg-gray-800 flex justify-center items-center p-2 mr-3 text-sm w-6 text-white;
        aspect-ratio: 1 / 1;
      }

      span {
        @apply text-blue-900 italic;
      }

      input:focus {
        @apply text-lg text-blue-900 font-bold;
      }
    }

    .icon {
      @apply text-blue-900;

      &:hover {
        @apply text-blue-700 cursor-pointer;
      }
    }

    .actions {
      @apply flex;
    }
  }
}
</style>

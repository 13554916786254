import { Entity } from '@decahedron/entity'

export class Pagination extends Entity {
  currentPage: number = null
  total: number = null
  perPage = 50
  from?: number = null
  lastPage?: number = null
  to?: number = null
}

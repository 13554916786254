import { IListResponse, IRepository } from '@/core/repositories/IRepository'
import axios, { AxiosInstance, AxiosPromise, AxiosRequestConfig, AxiosResponse } from 'axios'
import { CancelablePromise } from '@/core/repositories/CancelablePromise'
import PathBuilder, { IParams, IQuery } from '@/core/repositories/PathBuilder'
import { Entity, EntityBuilder } from '@decahedron/entity'
import { Pagination } from '@/core/repositories/Pagination'

interface ClassType<T> {
  new (): T;
}

export abstract class AbstractRepository<T extends Entity> implements IRepository<T> {
  protected abstract url: string
  protected abstract entity: ClassType<T>
  protected responseTransformer = (response: AxiosResponse) => response.data
  protected baseUrl: string = null
  protected axios: AxiosInstance = axios

  protected getOne (id: number | string, params?: IParams): CancelablePromise<T> {
    params = (params) ? { id, ...params } : { id }

    const url = !this.url.includes('/:id') ? `${this.url}/:id` : this.url
    return this.get(url, params)
      .then(response => EntityBuilder.buildOne<T>(this.entity, this.responseTransformer(response).data))
  }

  // Find is here to fulfill the IRepository interface requirements and to simply use that everywhere publicly without declaring find in each repo separately.
  find (id: number | string): CancelablePromise<T> {
    return this.getOne(id)
  }

  protected getPaginated (url: string, params?: IParams, query?: IQuery): CancelablePromise<IListResponse<T>> {
    return this.get(url, params, query)
      .then((response) => {
        const result = this.responseTransformer(response)

        return {
          items: EntityBuilder.buildMany<T>(this.entity, result.data),
          pagination: EntityBuilder.buildOne<Pagination>(Pagination, result.pagination || result.meta)
        }
      })
  }

  public update (id: number | string, data: T | FormData | Entity & Partial<T>, params?: IQuery): Promise<T> {
    return this.put(`${this.url}/:id`, data, { id, ...params })
  }

  public create (data: T | FormData, params?: IParams): Promise<T> {
    const payload = (data instanceof Entity) ? data.toJson() : data
    const config: AxiosRequestConfig = (this.baseUrl) ? { baseURL: this.baseUrl } : null
    return this.axios.post(PathBuilder(this.url, params), payload, config)
      .then((response) => {
        const result = this.responseTransformer(response)
        return EntityBuilder.buildOne<T>(this.entity, result.data)
      })
  }

  public delete (id: number | string, params?: IParams): AxiosPromise<void> {
    const config: AxiosRequestConfig = (this.baseUrl) ? { baseURL: this.baseUrl } : null
    return this.axios.delete(`${this.url}/${id}`, config)
  }

  protected get (url: string, params?: IParams, query?: IQuery): CancelablePromise<AxiosResponse> {
    const cancelSource = axios.CancelToken.source()
    const config: AxiosRequestConfig = {
      cancelToken: cancelSource.token
    }
    if (this.baseUrl) config.baseURL = this.baseUrl

    // Thanks to this https://madhatted.com/2013/6/16/you-do-not-understand-browser-history
    // we add a unique string to cache bust browser history back
    const requestPromise: CancelablePromise<AxiosResponse> = new CancelablePromise<AxiosResponse>((resolve, reject) => {
      this.axios.get(PathBuilder(url, params, { c: Date.now().toString(16), ...query }), config)
        .then(response => resolve(response))
        .catch(reason => reject(reason))
    })
    requestPromise.cancel = () => {
      cancelSource.cancel()
      requestPromise.reject('canceled')
    }

    return requestPromise
  }

  protected getAll (url: string, params?: IParams, query?: IQuery): CancelablePromise<IListResponse<T>> {
    return this.get(url, params, query)
      .then((response) => {
        const result = this.responseTransformer(response)
        return {
          items: EntityBuilder.buildMany<T>(this.entity, result.data),
          pagination: null
        }
      })
  }

  protected put (url: string, data: T | FormData | string | { [key: string]: any } | Entity & Partial<T>, params?: IQuery): Promise<T> {
    const payload = (data instanceof Entity) ? data.toJson() : data

    const config: AxiosRequestConfig = (this.baseUrl) ? { baseURL: this.baseUrl } : null
    return this.axios.put(PathBuilder(url, params), payload, config)
      .then((response) => {
        const result = this.responseTransformer(response)
        return EntityBuilder.buildOne<T>(this.entity, result.data)
      })
  }

  // wotan-disable-next-line no-misused-generics
  protected post<U = T> (url: string, data?: T | FormData | string | { [key: string]: any } | Entity & Partial<T>, params?: IQuery): Promise<U> {
    const payload = (data instanceof Entity) ? data.toJson() : data

    const config: AxiosRequestConfig = (this.baseUrl) ? { baseURL: this.baseUrl } : null
    return this.axios.post(PathBuilder(url, params), payload, config)
      .then((response) => {
        const result = this.responseTransformer(response)
        // support a null entity if the repository wants to do all decoding
        return this.entity ? EntityBuilder.buildOne<T>(this.entity, result.data) : result as U
      })
  }
}
